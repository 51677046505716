<template>
  <div class="donate-form"  id="user-profile" style="padding: 15px">
    <b-overlay :show="firstLoading">
      <b-row no-gutters>
        <b-col style="padding-bottom: 30px">
          <div class="left">
            <img :src="user.avatar" width="40px" />  <b>{{ user.name }}</b> NimoTv
          </div>
          <div class="right">
            <a href="/login">Login</a>
          </div>
        </b-col>
      </b-row>
      <b-row class="row" >
        <b-col sm="12" md="8" lg="8">
          <b-card>
            <b-form @submit="onSubmit">
              <b-row class="row">
                <b-col cols="12">
                  <b-form-input type="number" style="font-size: 30px;text-align:center;" v-model="form.amount" placeholder="Số tiền"  required></b-form-input>
                </b-col>
                <b-col cols="12" style="text-align:center; padding-top:5px">
                  <b-button @click="moneyChoose(10000)" size="sm">10.000đ</b-button><b-button @click="moneyChoose(20000)" size="sm">20.000đ</b-button><b-button size="sm" @click="moneyChoose(50000)">50.000đ</b-button><b-button size="sm" @click="moneyChoose(100000)">100.000đ</b-button>
                </b-col>
                <b-col cols="12" style="text-align:center;padding-top:10px;color:red;">
                  Cộng thêm phí 3.000 vnd cho cổng thanh toán
                </b-col>
              </b-row>
              <b-row class="row">
                <b-col cols="12">
                  <b-form-input placeholder="Họ tên" v-model="form.full_name" required ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="padding">
                  <b-form-textarea id="textarea" placeholder="Nôi dung gửi đến streamer..." rows="2" max-rows="6" v-model="form.description" required></b-form-textarea>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="padding">
                  <b-form-checkbox > Nội dung gửi riêng không hiển thị</b-form-checkbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="padding">
                  <b-button type="submit" variant="primary"><b-spinner small v-if="loading"></b-spinner> Donate</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
        <b-col sm="12" md="4" lg="4">
          <b-row>
            <b-col cols="12">
              <b-card :img-src="image_demo" no-body />
            </b-col>
            <b-col
              v-if="user.designation !== ''"
              cols="12">
              <b-card>
                {{ user.designation }}
              </b-card>
            </b-col>
            <b-col cols="12">
              <b-card>
                <b-tabs content-class="mt-3">
                  <b-tab title="Top" active>
                    <b-row v-if="tops.length < 1">
                      <b-col>Chưa có gì</b-col>
                    </b-row>
                    <b-row v-for="(top, k) in tops" v-bind:key="top.id">
                      <b-col lg="12">
                        {{ k+1 }}. <b>{{ top.name }}</b> ({{ top.amount }} vnđ)
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-overlay>
    <div id="footer">
      Power by EpisCloud.Com
    </div>
  </div>
</template>
<script>
import { BTab, BTabs, BCard, BFormCheckbox, BOverlay, BFormInput, BFormTextarea, BButton, BForm, BRow, BCol, BSpinner } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {
  isUserLoggedIn,
} from '@/auth/utils'

const currencyMask = createNumberMask({
  prefix: '',
  suffix: ' đ',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
})
export default {
  components: {
    BTab,
    BTabs,
    BOverlay,
    BFormCheckbox,
    BCard,
    BFormInput,
    BFormTextarea,
    BButton,
    BForm,
    BCol,
    BRow,
    BSpinner,
  },
  data() {
    return {
      firstLoading: true,
      isLoggedIn: isUserLoggedIn(),
      currencyMask,
      image_demo: require('@/assets/images/donate/demo.png'),
      user: {
        cover: null,
        avatar: 'https://storage-vietnam.myepis.cloud/113/default_propic.jpg',
        designation: null,
        nimotv: null,
        facebook_url: null,
        youtube_channel: null,
        name: null,
      },
      loading: false,
      form: {
        full_name: null,
        amount: null,
        description: null,
        phone: null,
        email: null,
      },
      tops: null,
    }
  },
  methods: {
    moneyChoose(m) {
      this.form.amount = m
    },
    onSubmit(event) {
      event.preventDefault()
      this.loading = true
      this.$http.post(`/donate/${this.$route.params.id}`, {
        ...this.form,
      }).then(response => {
        this.loading = false
        if (response.data.result === true) {
          window.location.href = response.data.data
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error!',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.error,
            },
          })
        }
      })
    },
  },
  created() {
    this.firstLoading = true
    this.$http.get(`/donate/${this.$route.params.id}`).then(({ data }) => {
      this.user = data.data
      // this.$http.get(`/donate/${this.$route.params.id}`).then(res => {
      this.tops = data.tops
      this.firstLoading = false
      // })
    }).catch(e => {
      // 404
      if (e.response.status === 404) {
        this.$router.push({ name: 'error-404' })
      }
    })
  },
}
</script>
<style lang="scss" >
  @import '@core/scss/base/pages/page-profile.scss';
</style>
<style scoped>
  .nav {
    padding: -35px !important;
  }
  .row {
    margin-bottom: 10px;
  }
  #footer {
    position:absolute;
    text-align: center;
    bottom:0;
    width:100%;
    height:30px;
  }
  .left {
    float: left;
  }
  .right {
    top: 10px;
    position: absolute;
    right: 0px;
  }
  .nav .left #name {
    font-weight: 500;
    font-size: 15px;
  }
</style>
